import { Flex, Text } from '@chakra-ui/react';

const Footer = () => {
  return (
    <Flex mt={'80px'} justify={'center'} align={'center'} mb={'20px'}>
      <Text fontSize={['16px']} fontWeight={500}>
        Open-source software. Made with ❤️ on Bitcoin.
      </Text>
    </Flex>
  );
};

export default Footer;
