import { CDN_URL } from '@/configs';
import { useConfig } from '@/state/config/hooks';
import { Flex, Image, Text } from '@chakra-ui/react';
import StatusMetamask from './Wallet/StatusMetamask';

const Header = () => {
  const { metaConfig } = useConfig();

  return (
    <Flex
      minH={'80px'}
      flexDir={'row'}
      alignItems={'center'}
      justifyContent={'space-between'}
    >
      {/* Logo & Title */}
      <Flex
        flexDir={'row'}
        alignItems={'center'}
        gap={'10px'}
        onClick={() => {
          window.open(window.location.origin, '_self');
        }}
        _hover={{
          cursor: 'pointer',
          opacity: 0.8,
        }}
      >
        <Image
          w={'48px'}
          h={'auto'}
          src={metaConfig.favIconUrl || `${CDN_URL}/images/names-logo.svg`}
        />
        <Text fontSize={'24px'} fontWeight={600}>
          {metaConfig.tabTitle || 'Trustless Bridge'}
        </Text>
      </Flex>

      {/* Metamask Walelt */}
      <StatusMetamask />
    </Flex>
  );
};

export default Header;
