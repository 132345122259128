import { Flex } from '@chakra-ui/react';
import React, { FC, PropsWithChildren } from 'react';
import Footer from './Footer';
import Header from './Header';

const Layout: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <Flex
      minH={'100vh'}
      maxW={'1920px'}
      ml="auto"
      mr="auto"
      flexDir={'column'}
      p="0 32px"
    >
      <Header />
      <Flex minH={'80vh'}>{children}</Flex>
      <Footer />
    </Flex>
  );
};

export const withLayout = (WrappedComponent: FC) => (props: any) => {
  return (
    <Layout>
      <WrappedComponent {...props}></WrappedComponent>
    </Layout>
  );
};

export default Layout;
